<template>
  <div>
    <search
      :scope="scope"
      hide-document-tabs
      hide-actions
      use-responsive-search-field
      ref="search"
    />
  </div>
</template>
<script>
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    Search: () => import('@/pages/search/components/search')
  },
  data () {
    return {
    }
  },
  computed: {
    scope () {
      return Scope.allocationAnnoucement(this.document.id)
    }
  },
  methods: {
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 400px
</style>
